
import { defineComponent } from "vue";



import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import JwtService from "@/core/services/JwtService";
export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu
  },
   setup() {
     return {
       JwtService
     }
   }
});
